// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
import Vue from 'vue/dist/vue.common.dev'
import html2canvas from 'html2canvas/dist/html2canvas'
import draggable from 'vuedraggable'
import $ from 'jquery';
require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-bs4')(window, $)



$(document).ready(function(){
    $('.dtable').DataTable();
});

if(gon.adapt){
    let adaptApp = new Vue({
        el: "#adapt-app",
        components: {
            draggable,
        },
        data: {
            adapt: gon.adapt,
            addLayerMode: false,
            newLayerType: "background",
            newLayer: "",
            layers: gon.adapt.layers,
            initialX: 0,
            initialY: 0,
            selectedLayerIds: [],
        },
        methods: {
            toggleSelect(id){
                if(this.isSelected(id)){
                    this.selectedLayerIds = this.selectedLayerIds.slice().filter(x => x!=id);
                } else {
                    this.selectedLayerIds.push(id);
                }
            },
            isSelected(id){
                return this.selectedLayerIds.indexOf(id)>-1;
            },
            dragStart(e){
                this.initialX = e.layerX;
                this.initialY = e.layerY;
            },
            dragOver(e){
                this.selectedLayers.forEach(selectedLayer => {
                    selectedLayer.x += (e.layerX - this.initialX)*1/selectedLayer.scale;
                    selectedLayer.y += (e.layerY - this.initialY)*1/selectedLayer.scale;
                });
                this.initialX = e.layerX;
                this.initialY = e.layerY;
            },
            wheel(e){
                if(this.selectedLayerIds.length>0){
                    console.log(e);
                    e.stopPropagation();
                    e.preventDefault();
                    let delta = -0.1;
                    //Upscale
                    if(e.deltaY<0){
                        delta = 0.1;
                    } 
                    this.selectedLayers.forEach(layer => {
                        layer.scale+=delta;
                    });
                }
            },
            drag(e){
            },
            makeImages(){
                $('.out-images').html('');
                $('.sandbox').addClass('bleed');
                document.querySelectorAll('.sandbox').forEach(x => {
                    if(!x.classList.contains('six')){
                        html2canvas(x).then(canvas => {
                            document.querySelector('.out-images').appendChild(canvas);
                        });
                    }
                });

                $('.sandbox').removeClass('bleed');
            },
            appendLayer(){
                const layerObject = gon.layers[""+this.newLayer];
                this.layers.push({
                    id: this.newLayer,
                    x: 0,
                    y: 0,             
                    scale: 1,
                    filename: layerObject.image_file_name,
                    thumbnail: layerObject.thumbnail_url,
                    image_url: layerObject.image_url,
                });
                this.addLayerMode = false;
            },
            toggleAddLayer(){
                this.addLayerMode = !this.addLayerMode;
            },
            addLayer(){
                this.addLayerMode= true;
            },
            deleteLayer(id){
                this.layers = this.layers.slice().filter(x => x.id != id);
            },
            save(){
                const self = this;
                $.ajax({
                    method: "PUT",
                    url: `/compositions/${self.adapt.composition_id}/adapts/${self.adapt.id}.json`,
                    contentType: "application/json",
                    data: JSON.stringify({
                        adapt: {
                            layers: self.layers,
                        }
                    }),
                    success: function(){
                        alert("Adapt was saved");
                    },
                });
            }
        },
        computed: {
            selectedLayers(){
                return this.layers.filter(x => this.isSelected(x.id));
            }
        }
    });
}

if(gon.adapt){
    $(document).on('keydown', function(e){
        if(e.target == document.body){
            /* Number key */
            if(e.keyCode>=49 && e.keyCode <=57){
                const index = e.keyCode-48;
                if(adaptApp.layers.length > index-1){
                    adaptApp.toggleSelect(adaptApp.layers[index-1].id);
                }
            }

            if(e.keyCode == 192){
                adaptApp.toggleAddLayer();
            }
        }
    });
}
